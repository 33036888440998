<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-select
          :items="payment_types"
          label="Solo field"
          v-model="seleceted_payment_type"
          color="#002441"
          item-color="#002441"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="seleceted_payment_type == 'PayWithTransactionId'"
          label="Amount"
          type="number"
          color="#002441"
          :hint="hint"
          persistent-hint
        >
          <template v-slot:message="{ message }">
            <div class="red--text">
              {{ message }}
            </div>
          </template>
        </v-text-field>
        <v-text-field
          v-else
          label="Transaction ID"
          color="#002441"
          :hint="hint"
          persistent-hint
        >
          <template v-slot:message="{ message }">
            <div class="red--text">
              {{ message }}
            </div>
          </template></v-text-field
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="#002441" elevation="0" dark disabled>Pay With BKash</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payment_types: ["PayWithTransactionId", "PayWithAccountNumber"],
      seleceted_payment_type: "PayWithTransactionId",
      hint:
        "* Service fee of 1.25% is applicable, If your daily transaction limit exceeds BDT 10000.00",
    };
  },
};
</script>

<style>
</style>